import { Spinner } from "components";
import { useAnalyticsEventCount } from "hooks/queries/useAnalytics";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { PRIMARY_COLOR } from "utils/global/constants";

const BarGraph = ({ startDate, endDate, eventType, targetName }) => {
  const { data, isLoading, error } = useAnalyticsEventCount(
    startDate,
    endDate,
    eventType,
    targetName
  );

  if (isLoading) return <Spinner />;
  if (error) return <div>Error fetching data</div>;

  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" fill={PRIMARY_COLOR} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <div className="recharts-custom-tooltip">
        <p>{label}</p>
        <p
          className="m-0"
          style={{
            color: payload[0].color,
          }}
        >
          {t("analytics:count")}: {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

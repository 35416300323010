import { Button } from "antd";
import WorkerParkedCar from "components/workerOrders/WorkerParkedCar";
import WorkerParkedCarList from "components/workerOrders/WorkerParkedCarList";
import WorkerUpload from "components/workerOrders/WorkerUpload";
import FileSaver from "file-saver";
import { useInstallationLogPDFGeneration } from "hooks/queries/useInstallation";
import { useParkedCarList } from "hooks/queries/useParkedCar";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf } from "react-icons/ai";
import { InstallationSchema } from "types/installation";

export default function InstallationProtocolTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { id } = installation;
  const { data: parkedCars } = useParkedCarList(id);

  const { mutate, isLoading } = useInstallationLogPDFGeneration(id);

  const generatePDF = () => {
    mutate(undefined, {
      onSuccess: (data) => {
        FileSaver.saveAs(data.data.fileUrl, data.data.name);
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center gap-2">
        <h5 className="underline">
          {t("orders:modalTabs:installationProtocol")}
        </h5>
        <Button type="primary" onClick={generatePDF} loading={isLoading}>
          <AiOutlineFilePdf />
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <WorkerParkedCar installationId={id} />
        <WorkerParkedCarList installationId={id} parkedCars={parkedCars} />
        <WorkerUpload installationId={id} />
      </div>
    </div>
  );
}

import { FormInstance, message } from "antd";
import { EmailData } from "components/orders/EmailModal";
import { INSTALLATION_LIST_QUERY_KEY } from "hooks/queries/useInstallation";
import { GET_STATUS_WISE_COUNT_QUERY_KEY } from "hooks/queries/useStatusWiseCount";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { Attachment } from "types/attachment";

export const GET_ATTACHMENTS_QUERY_KEY = "attachemnt-list";
const ADMIN_FILES_END_POINT = "/installation";

export const useAdminFileUpload = (id: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: FormData) => {
      await new AuthorizedService().post(
        `${ADMIN_FILES_END_POINT}/${id}/attachment`,
        data
      );
    },
    onSuccess: () => {
      message.success(t("upload:uploadSuccess"));
    },
    onError: () => {
      message.error(t("upload:uploadFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_ATTACHMENTS_QUERY_KEY, id]);
    },
  });
};

export const useAdminUploadedFileList = (id: string) => {
  return useQuery<Attachment[]>({
    queryKey: [GET_ATTACHMENTS_QUERY_KEY, id],
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        `${ADMIN_FILES_END_POINT}/${id}/attachment`
      );
      return res.data;
    },
  });
};

export const useAdminFileDelete = (installationId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileId: string) => {
      await new AuthorizedService().delete(
        `${ADMIN_FILES_END_POINT}/attachment/${fileId}`
      );
    },
    onSuccess: () => {
      message.success(t("general:deleteSuccess"));
    },
    onError: () => {
      message.error(t("general:deleteFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        GET_ATTACHMENTS_QUERY_KEY,
        installationId,
      ]);
    },
  });
};

export const useAdminFileForCustomerStatus = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Attachment) => {
      await new AuthorizedService().put(
        `${ADMIN_FILES_END_POINT}/${data.id}/status-customer`,
        data
      );
    },
    onSuccess: () => {
      message.success(t("status:changedSuccessfully"));
    },
    onError: () => {
      message.error(t("status:error"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_ATTACHMENTS_QUERY_KEY]);
    },
  });
};

interface Props {
  id: string;
  form: FormInstance;
  handleCancel: () => void;
}

export const useAdminSendEmailToCustomer = ({
  id,
  form,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: EmailData) => {
      await new AuthorizedService().post(
        `${ADMIN_FILES_END_POINT}/${id}/send-attachment-to-client`,
        data
      );
    },
    onSuccess: () => {
      message.success(t("email:sendSuccessfully"));
      form.resetFields();
      handleCancel();
    },
    onError: () => {
      message.error(t("email:error"));
    },
    onSettled: () => {
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
    },
  });
};
